<template>
  <div class="pageContol">
    <!-- 列表部分 -->
    <div class="framePage">
      <!-- 页面位置 -->
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">培训管理</a>
          <i>></i>
          <a href="javascript:;" @click="goHerf()" class="cur-a"
            >问卷活动管理</a
          >
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">反馈记录</a>
        </span>
      </div>
      <div class="framePage-body">
        <!-- 操作区 -->
        <div class="operationControl flexcb" style="align-items: flex-start">
          <div class="searchbox" style="margin-bottom: 15px">
            <div title="姓名" class="searchboxItem ci-full">
              <span class="itemLabel">姓名:</span>
              <el-input
                v-model="userName"
                type="text"
                size="small"
                placeholder="请输入姓名"
                clearable
              />
            </div>

            <div title="身份证号" class="searchboxItem ci-full">
              <span class="itemLabel">身份证号:</span>
              <el-input
                v-model="idCard"
                type="text"
                size="small"
                placeholder="请输入身份证号"
                clearable
              />
            </div>

            <div title="联系电话" class="searchboxItem ci-full">
              <span class="itemLabel">联系电话:</span>
              <el-input
                v-model="mobile"
                type="text"
                size="small"
                placeholder="请输入联系电话"
                clearable
              />
            </div>

            <el-button
              class="bgc-bv"
              style="margin: 0 10px"
              round
              @click="getData()"
              >查询</el-button
            >
          </div>
        </div>

        <!-- 列表 -->
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                :index="indexMethod"
                fixed
                width="100px"
              />
              <el-table-column
                label="姓名"
                align="left"
                prop="userName"
                show-overflow-tooltip
                width="280"
                fixed
              />
              <el-table-column
                label="身份证号"
                align="left"
                show-overflow-tooltip
              >
                <template slot-scope="scope">{{
                  scope.row.idCard || "--"
                }}</template>
              </el-table-column>

              <el-table-column
                label="联系电话"
                align="left"
                prop="mobile"
                show-overflow-tooltip
              />
              <el-table-column
                label="提交时间"
                align="left"
                prop="createTime"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  {{ scope.row.createTime | moment }}
                </template>
              </el-table-column>
              <el-table-column
                label="操作"
                fixed="right"
                align="center"
                width="200px"
              >
                <div slot-scope="scope" class="flexcc">
                  <el-button
                    type="text"
                    style="padding: 0px 5px"
                    size="mini"
                    @click="showPaper(scope.row)"
                    >预览</el-button
                  >
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>

        <!-- 分页 -->
        <PageNum
            :apiData="apiData"
            @sizeChange="sizeChange"
            @getData="getData"
        />
      </div>
    </div>

    <!-- 新增 -->
    <el-dialog
      title="新增问卷活动"
      :visible.sync="dialogFormVisible"
      width="40%"
      @close="closeModel"
    >
      <el-form
        ref="groupForm"
        :model="groupForm"
        style="width: 100%"
        :rules="rules"
      >
        <el-form-item
          label="问卷调查名称："
          label-width="120px"
          prop="paperName"
        >
          <!-- <el-input
            v-model="groupForm.paperName"
            size="small"
            maxlength="50"
            show-word-limit
          ></el-input> -->
          <el-select v-model="value2" placeholder="请选择" style="width: 400px">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
              :disabled="item.disabled"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item
          label="问卷有效期："
          label-width="120px"
          prop="paperDescription"
        >
          <!-- <el-input
            type="textarea"
            v-model="groupForm.paperDescription"
            size="small"
            maxlength="1200"
            show-word-limit
          ></el-input> -->

          <div class="flex">
            <div>
              <el-radio v-model="radio" label="1">无限期</el-radio>
              <el-radio v-model="radio" label="2">截止期</el-radio>
            </div>

            <div style="width: 200px; margin-left: 10px">
              <el-date-picker
                v-model="value1"
                type="date"
                placeholder="选择日期"
              >
              </el-date-picker>
            </div>
          </div>
        </el-form-item>

        <el-form-item
          label="推送对象："
          label-width="120px"
          prop="paperDescription"
        >
          <!-- <el-input
            type="textarea"
            v-model="groupForm.paperDescription"
            size="small"
            maxlength="1200"
            show-word-limit
          ></el-input> -->

          <div class="flex">
            <div>
              <el-radio v-model="radio" label="1">全部用户</el-radio>
              <el-radio v-model="radio" label="2">指定班级</el-radio>
            </div>

            <div style="width: 200px; margin-left: 10px">
              <el-select
                v-model="value8"
                multiple
                filterable
                placeholder="请选择"
              >
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
          </div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer flexcc">
        <el-button @click="closeModel">取 消</el-button>
        <el-button class="bgc-bv" type="primary" @click="doOk">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "operate/Testquestionbank",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      value1: "", // 时间
      radio: "1", // 问卷有效期
      value2: "",
      value8: [],
      options: [
        {
          value: "选项1",
          label: "黄金糕",
        },
        {
          value: "选项2",
          label: "双皮奶",
        },
        {
          value: "选项3",
          label: "蚵仔煎",
        },
        {
          value: "选项4",
          label: "龙须面",
        },
        {
          value: "选项5",
          label: "北京烤鸭",
        },
      ],
      tableData: [], // 反馈信息列表
      organName: "", // 机构名称
      idCard: "", // 身份证
      mobile: "", // 手机号
      userName: "", // 姓名
      dialogFormVisible: false,
      groupForm: {
        paperName: "",
        paperDescription: "",
      },
      rules: {
        paperName: [
          { required: true, message: "请输入问卷名称", trigger: "blur" },
        ],
        paperDescription: [
          { required: true, message: "请输入问卷描述", trigger: "blur" },
        ],
      },
      status: "1", // 状态
      // 状态列表
      statusList: [
        {
          value: "1",
          label: "全部",
        },
        {
          value: "2",
          label: "进行中",
        },
        {
          value: "3",
          label: "已结束",
        },
      ],
    };
  },
  created() {},
  computed: {},
  methods: {
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        paperActivityId: this.$route.query.paperActivityId,
      };
      if (this.userName) {
        params.userName = this.userName;
      }
      if (this.idCard) {
        params.idCard = this.idCard;
      }
      if (this.mobile) {
        params.mobile = this.mobile;
      }
      this.doFetch({
        url: "/edu/project/paper/feedback",
        params,
        pageNum,
      });
    },
    addPaper() {
      this.dialogFormVisible = true;
    },
    //弹框确定
    doOk() {
      this.$refs["groupForm"].validate((valid) => {
        if (valid) {
          let params = {
            paperName: this.groupForm.paperName || "",
            paperDescription: this.groupForm.paperDescription || "",
          };
          this.$post("/survey/sr-paper/insert", params).then((ret) => {
            if (ret.status == "0") {
              this.$message({
                message: ret.message,
                type: "success",
              });
              this.closeModel();
              this.getData(-1);
            }
          });
        }
      });
    },
    //弹框关闭
    closeModel() {
      this.$refs["groupForm"].resetFields();
      this.dialogFormVisible = false;
    },
    getSwitch(paperId, published) {
      this.$post("/survey/sr-paper/published", { paperId, published })
        .then((ret) => {
          if (ret.status == "0") {
            this.$message({
              type: "success",
              message: "修改成功",
            });
            this.getData(-1);
          }
        })
        .catch((err) => {
          return;
        });
    },
    deletePaper(paperId) {
      this.doDel({
        url: "/survey/sr-paper/deletePaper",
        msg: "你确定要删除该问卷吗？",
        ps: {
          type: "post",
          data: { paperId },
        },
      });
    },
    // 跳转调查活动
    goHerf() {
      this.$router.push({
        path: "/web/questionnaire/questionnaireActivity",
        query: {},
      });
    },
    // 跳转预览页面
    showPaper(row) {
      let idCard = row.idCard
      let mobile = row.mobile
      let userName = row.userName
      let performanceId = row.performanceId
      
      let paperId = this.$route.query.paperId
      this.$router.push({
        path: "/web/questionnaire/preview",
        query: {
          mobile,
          idCard,
          userName,
          performanceId,
        },
      });
    },
    /* 导出 */
    exportPaper(paperId) {
      this.$post("/biz/paper/exportPaper", {
        paperId,
      })
        .then((ret) => {
          window.open(ret.data);
        })
        .catch((err) => {
          return;
        });
    },
    /* 导出 - word */
    exportWord(paperId) {
      this.$post("/biz/paper/exportPaperWord", {
        paperId,
      })
        .then((ret) => {
          window.open(ret.data);
        })
        .catch((err) => {
          return;
        });
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 2.55) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1 + 40;
      }
      if (page) {
        tHeight -= 25;
      }
      this.tableHeight = tHeight;
    },
  },
  beforeRouteLeave: resetKeepAlive,
  watch: {
    $route: {
      handler: function (val, oldVal) {
        if (val.query.refresh == true) {
          this.getData(-1);
        }
      },
      // 深度观察监听
      deep: true,
    },
  },
};
</script>
<style lang="less" scope>
.el-textarea__inner {
  min-height: 20rem !important;
  resize: none;
}
// .itemLabel {
//   width: 180px;
//   // background: #000;
// }
// .itemLabel2 {
//   width: 10px;
//   // background: #000;
// }

.flex {
  display: flex;
}
</style>
